import Layout from '@/layout'

const IptvRouter = {
    name: 'Iptv',
    path: '/iptv/',
    redirect: '/iptv/service_list/',
    component: Layout,
    permission: 'iptv.view_iptv',
    meta: { title: 'IPTV', icon: 'fa-tv', cache: true },

    children: [
        {
            path: 'service_list/',
            component: () => import('@/modules/Iptv/ServiceList'),
            name: 'ServiceList',
            meta: { title: 'Service List', icon: 'fa-tv' },
        },
        {
            path: 'devices/',
            component: () => import('@/modules/Iptv/views/IPTvDevicesList'),
            name: 'IPTvDevicesList',
            meta: { title: 'Devices List', icon: 'fa-desktop' },
        },
        {
            path: 'tariff_list/',
            component: () => import('@/modules/Iptv/TariffList'),
            name: 'TariffList',
            meta: { title: 'Tariff List', icon: 'fa-money' },
            children: [
                {
                    path: ':id/',
                    hidden: true,
                    meta: {
                        title: 'Iptv',
                        icon: 'fa-list',
                    },
                },
            ],
        },
        {
            path: 'user_list/',
            component: () => import('@/modules/Iptv/IptvUserList'),
            name: 'IptvUserList',
            props: true,
            meta: {
                title: 'Iptv',
                icon: 'fa-list',
            },
            children: [
                {
                    path: ':id',
                    component: () => import('@/modules/Iptv/IptvUserList'),
                    hidden: true,
                    props: true,
                    meta: {
                        title: 'Iptv',
                        icon: 'fa-list',
                    },
                },
                {
                    path: ':id/:tp_id/',
                    component: () => import('@/modules/Iptv/IptvUserList'),
                    hidden: true,
                    props: true,
                    meta: {
                        title: 'Iptv',
                        icon: 'fa-list',
                    },
                },
            ],
        },
        {
            path: '/iptv/:id/info/',
            component: () => import('@/modules/Iptv/infoView'),
            name: 'infoView',
            hidden: true,
            meta: { title: 'Info', icon: 'list' },
        },
    ],
}
export default IptvRouter
