;+(function (document) {
    'use strict'
    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('.dropdown-toggle').forEach(function (a) {
            const drop = a.parentElement.querySelector('ul')
            a.onclick = () => {
                if (drop) {
                    drop.classList.toggle('dropdown-open')
                }
                a.onblur = () => {
                    drop.classList.toggle('dropdown-open')
                }
            }
        })
    })
})(document)
