/* eslint-disable */
;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        ? (module.exports = factory(require('../../DatePicker')))
        : typeof define === 'function' && define.amd
        ? define('DatePicker', factory)
        : (global = global || self)(
              (global.DatePicker = global.DatePicker || {})(
                  (global.DatePicker.lang = global.DatePicker.lang || {})
              )((global.DatePicker.lang.uk = factory(global.DatePicker)))
          )
})(this, function (DatePicker) {
    'use strict'

    DatePicker =
        DatePicker && DatePicker.hasOwnProperty('default') ? DatePicker['default'] : DatePicker

    function unwrapExports(x) {
        return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default')
            ? x['default']
            : x
    }

    function createCommonjsModule(fn, module) {
        return (module = { exports: {} }), fn(module, module.exports), module.exports
    }

    const uk = createCommonjsModule(function (module, exports) {
        Object.defineProperty(exports, '__esModule', {
            value: true,
        })
        exports['default'] = void 0
        const locale = {
            months: [
                'січень',
                'лютий',
                'березень',
                'квітень',
                'травень',
                'червень',
                'липень',
                'серпень',
                'вересень',
                'жовтень',
                'листопад',
                'грудень',
            ],
            monthsShort: [
                'січ',
                'лют',
                'бер',
                'квіт',
                'трав',
                'черв',
                'лип',
                'серп',
                'вер',
                'жовт',
                'лист',
                'груд',
            ],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7,
        }
        const _default = locale
        exports['default'] = _default
        module.exports = exports.default
    })

    const uk$1 = unwrapExports(uk)

    const lang = {
        formatLocale: uk$1,
        yearFormat: 'YYYY',
        monthFormat: 'MMM',
        monthBeforeYear: true,
    }
    DatePicker.locale('uk', lang)

    return lang
})
