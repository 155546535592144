import Vue from 'vue'
import App from './App.vue'
import Paginate from 'vuejs-paginate'
import router from './router'
import store from './store'
import AclPlugin from './lib/acl'
import VueToast from 'vue-toast-notification'
import session from './utils/session'
import ws from './utils/ws'
import './lib/css'
import './lib/script'

// import './utils/error-log'

Vue.use(VueToast)
Vue.use(AclPlugin)
Vue.use(Paginate)

Vue.config.productionTip = false
Vue.prototype.$session = session
Vue.prototype.$ws = ws

Vue.prototype.$onMessage = (text, type) => {
    Vue.$toast.open({
        message: text,
        position: 'top-right',
        duration: 3000,
        type: type,
    })
}

Vue.config.productionTip = false

store
    .dispatch('user/accountDetails')
    .then(() => {})
    .finally(() => {
        new Vue({
            el: '#app',
            store,
            router,
            render: (h) => h(App),
        })
    })
