import store from '../store'
import router from '@/router'

const modulesFiles = require.context('../modules/', true, /.+\/routes\/index.js$/)

function permission(route) {
    if (
        store.getters.permission.includes(route.permission) ||
        route.permission === true ||
        route.permission === undefined
    ) {
        return true
    }
}

export function parseRouter() {
    let list = []
    modulesFiles.keys().reduce((modules, modulePath) => {
        const value = modulesFiles(modulePath)
        if (permission(value.default)) {
            if (value.default.children) {
                const subList = []
                value.default.children.forEach((r) => {
                    if (permission(r)) {
                        subList.push(r)
                    }
                })
                value.default.children = subList
            }
            router.addRoute(value.default)
            list.push(value.default)
        }
    }, {})
    return list
}
