import Vue from 'vue'
import Router from 'vue-router'
import Layout from './layout'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'DashboardView',
                component: () => import('@/views/dashboard/index'),
                meta: { title: 'Dashboard', icon: 'dashboard' },
            },
            {
                path: 'history',
                component: () => import('./views/History'),
                meta: { title: 'History', icon: 'history', affix: true },
            },
        ],
    },
    {
        path: '/login/',
        component: () => import('./views/login/index'),
        hidden: true,
    },

    { path: '*', component: () => import('./views/error-page/PageError404'), hidden: true },
]
const createRouter = () => new Router({ base: process.env.BASE_URL, routes: constantRoutes })

const router = createRouter()

router.beforeEach((to, from, next) => {
    const { title } = to.meta
    document.title = `${title} - ATools`
    next()
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
