import session from '../../utils/session.js'
import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        work: [],
    },

    actions: {
        async getWorks({ commit, state }, { filter, update }) {
            if (state.work.length === 0 || update === true) {
                state.work = []
                const response = await session.get('/work/list/', {
                    params: filter,
                })
                commit('GET_WORKS', response.data)
                return response
            }
        },

        searchWork({ commit, state }, q) {
            state.work = []
            session
                .get('/work/list/', {
                    params: q,
                })
                .then((response) => {
                    commit('GET_WORKS', response.data)
                })
        },

        addWork({ commit }, work) {
            commit('ADD_WORK', work)
        },

        updateWork({ commit }, work) {
            commit('UPDATE_WORK', work)
        },
        delWork({ commit }, work) {
            commit('DEL_WORK', work)
        },
    },

    getters: {
        works(state) {
            return state.work
        },
    },

    mutations: {
        GET_WORKS(state, work) {
            state.work = work
        },

        ADD_WORK(state, work) {
            state.work.unshift(work)
        },

        UPDATE_WORK(state, work) {
            const foundIndex = state.work.findIndex((element) => element.id === work.id)
            Vue.set(state.work, foundIndex, work)
        },

        DEL_WORK(state, work) {
            const removeIndex = state.work
                .map((item) => {
                    return item.id
                })
                .indexOf(work)
            state.work.splice(removeIndex, 1)
        },
    },
}
