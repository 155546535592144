const state = {
    routes: [],
}

const actions = {
    addRoute({ commit }, route) {
        commit('SET_ROUTE', route)
        return route
    },
}

const mutations = {
    SET_ROUTE: (state, route) => {
        state.routes = route
    },
}

const getters = {
    getRoutes(state) {
        return state.routes
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
