import Layout from '../../../layout'

const buildsRouter = {
    path: '/builds/',
    name: 'Builds',
    redirect: '/builds/list',
    component: Layout,
    permission: true,
    meta: { title: 'Покриття', icon: 'fa-list' },
    children: [
        {
            path: 'list',
            component: () => import('../views/BuildsView'),
            permission: 'Builds.view_buildinfo',
            name: 'BuildsView',
            meta: { title: 'Будинки', icon: 'fa-building', cache: true },
        },
        {
            path: ':id/view',
            component: () => import('../views/BuildView'),
            permission: true,
            name: 'BuildView',
            hidden: true,
            meta: { title: 'Будинки', icon: 'fa-building', cache: true },
        },
        {
            path: 'agents',
            component: () => import('../views/AgentsView'),
            permission: true,
            name: 'AgentsView',
            meta: { title: 'Агенти', icon: 'fa-users', cache: true },
        },
        {
            path: 'managements',
            component: () => import('../views/ManagementView'),
            permission: true,
            name: 'ManagementView',
            meta: { title: 'Управління', icon: 'fa-list', cache: true },
        },
        {
            path: 'contracts',
            component: () => import('../views/BuildContractsView'),
            permission: true,
            name: 'BuildContractsView',
            meta: { title: 'Договори', icon: 'fa-list', cache: true },
        },
        {
            path: 'map',
            component: () => import('../views/MapView'),
            permission: true,
            name: 'MapView',
            meta: { title: 'Map', icon: 'fa-location-arrow', cache: true },
        },
    ],
}

export default buildsRouter
