<template>
    <div class="wrapper">
        <div class="main-container">
            <div>
                <navi-bar />
                <side-bar />
                <content-wrap />
            </div>
        </div>
    </div>
</template>

<script>
import NaviBar from '../components/NaviBar'
import SideBar from '../components/SideBar'
import ContentWrap from '../components/ContentWrap'

export default {
    name: 'MainApp',
    components: {
        NaviBar,
        SideBar,
        ContentWrap,
    },
}
</script>
