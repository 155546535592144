const state = {
    events: [],
}

const actions = {
    addEvents({ commit }, event) {
        commit('SET_EVENT', event)
        return event
    },

    remEvents({ commit }, event) {
        commit('REM_EVENT', event)
        return event
    },
}

const mutations = {
    SET_EVENT: (state, events) => {
        const exist = state.events
            .map((item) => {
                return item.id
            })
            .indexOf(events.id)
        if (exist) {
            state.events.push(events)
        }
    },
    REM_EVENT: (state, events) => {
        const remove = state.events
            .map((item) => {
                return item.id
            })
            .indexOf(events.id)
        state.events.splice(remove, 1)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
