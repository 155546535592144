import Layout from '@/layout'

const networkRouter = {
    path: '/networks/',
    name: 'Networks',
    component: Layout,
    redirect: '/networks/mac/list',
    permission: true,
    meta: { title: 'Network', icon: 'fa-list' },
    children: [
        {
            path: 'mac/list',
            component: () => import('@/modules/Networks/modules/MacTest/MacApp'),
            permission: 'mac.view_mac',
            name: 'MacApp',
            meta: { title: 'Mac Test', icon: 'fa-list', cache: true },
        },
        {
            path: 'topology',
            component: () => import('@/modules/Networks/modules/Topology'),
            name: 'Topology',
            meta: { title: 'Topology', icon: 'fa-network-wired' },
        },
        {
            path: 'fastping',
            name: 'FastPing',
            component: () => import('@/modules/Networks/modules/FastPing/FastPing'),
            permission: true,
            meta: { title: 'Fast Ping', icon: 'fa-list' },
        },
        {
            path: 'devices/',
            name: 'DevicesView',
            component: () => import('@/modules/Networks/modules/Devices'),
            permission: true,
            meta: { title: 'Devices List', icon: 'fa-list', cache: true },
        },
        {
            path: ':id/device/',
            name: 'ViewDevice',
            component: () => import('@/modules/Networks/modules/Devices/views/ViewDevice'),
            hidden: true,
            meta: { title: 'Device', icon: 'fa-dev', cache: false },
        },
        {
            path: 'syslog/',
            name: 'SyslogView',
            component: () => import('@/modules/Networks/modules/Syslog'),
            meta: {
                title: 'Syslog',
                icon: 'fa-dev',
                cache: true,
                permission: 'Networks.view_syslog',
            },
        },
    ],
}

export default networkRouter
