import session from '../../utils/session.js'

export default {
    namespaced: true,

    state: {
        ItemCart: [],
    },

    actions: {
        async getItemsCart({ commit }) {
            const response = await session.get('/warehouse/cart/view/')
            commit('SET_CART_LIST', response.data)
            return response
        },
    },

    getters: {
        ItemsCart(state) {
            return state.ItemCart
        },
    },

    mutations: {
        SET_CART_LIST(state, ItemsCart) {
            state.ItemCart = ItemsCart
        },

        ADD_CART(state, item) {
            state.ItemCart.unshift(item)
        },

        DEL_CART(state, item) {
            const removeIndex = state.ItemCart.map((item) => {
                return item.id
            }).indexOf(item)
            state.ItemCart.splice(removeIndex, 1)
        },
    },
}
