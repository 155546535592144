import Layout from '@/layout'

const EmployeeRouter = {
    path: '/employee/',
    name: 'Employees',
    component: Layout,
    redirect: '/employee/list',
    permission: true,
    meta: { title: 'Співробітники', icon: 'fa-users', cache: true },

    children: [
        {
            path: 'list',
            component: () => import('@/modules/Employees/EmployeesList'),
            name: 'EmployeesList',
            permission: true,
            meta: { title: 'Співробітники', icon: 'fa-users', cache: true },
        },
        {
            path: 'Phone',
            component: () => import('@/modules/Employees/PhoneList'),
            permission: true,
            name: 'PhoneList',
            meta: { title: 'Список телефонів', icon: 'fa-phone', cache: true },
        },
        {
            path: ':id/profile/',
            component: () => import('@/modules/Employees/ProfileView'),
            permission: true,
            hidden: true,
            meta: { title: 'Профіль працівника', icon: 'list' },
        },
    ],
}
export default EmployeeRouter
