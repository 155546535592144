<template>
    <aside class="main-sidebar">
        <section class="sidebar">
            <div class="user-panel">
                <div class="pull-left image">
                    <img :src="getPhoto" class="img-circle" alt="User Image" />
                </div>
                <div class="pull-left info">
                    <p>{{ getUser.username }}</p>
                </div>
            </div>
            <form action="#" method="get" class="sidebar-form">
                <div class="input-group">
                    <input type="text" name="q" class="form-control" placeholder="Search..." />
                    <span class="input-group-btn">
                        <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                            <i class="fa fa-search" />
                        </button>
                    </span>
                </div>
            </form>
            <ul class="sidebar-menu">
                <router-link tag="li" to="/dashboard" active-class="active">
                    <a>
                        <i class="fa fa-dashboard" />
                        <span>Dashboard</span>
                    </a>
                </router-link>
            </ul>
            <item-list :routers="permissionRoutes" />
        </section>
    </aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SideBar',

    computed: {
        ...mapGetters({
            getUser: 'getUser',
            permission: 'permission',
            permissionRoutes: 'permission/getRoutes',
        }),
        getPhoto() {
            if (this.getUser.employee) {
                return this.getUser.employee.photo
            } else {
                return '/media/employee/photo/avatar.jpg'
            }
        },
    },
    components: {
        ItemList: () => import('./ItemList'),
    },
}
</script>

<style scoped>
.sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow-y: auto;
    z-index: 4;
    padding-bottom: 100px;
}
</style>
