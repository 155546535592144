<template>
    <div id="tags-view-container" class="tags-view-container" @scroll="isSticky">
        <scroll-pane ref="scrollPane" @scroll="handleScroll">
            <draggable :list="visitedViews">
                <div v-for="(tag, idx) in visitedViews" class="btn-group btn-group-xs" :key="idx">
                    <router-link
                        type="button"
                        class="btn"
                        ref="tag"
                        :class="isActive(tag) ? 'btn-success' : 'btn-primary'"
                        :key="tag.path"
                        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                    >
                        <template v-if="tag.meta.title">
                            {{ tag.meta.title }} {{ tag.params.id }}
                        </template>
                        <template v-else>
                            {{ tag.title }}
                        </template>
                    </router-link>
                    <button
                        type="button"
                        class="btn dropdown-toggle"
                        :class="isActive(tag) ? 'btn-success' : 'btn-primary'"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        @click="closeSelectedTag(tag)"
                    >
                        <i class="fa fa-remove"></i>
                    </button>
                </div>
            </draggable>
        </scroll-pane>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import ScrollPane from './ScrollPane'

export default {
    name: 'TagsView',

    data() {
        return {
            visible: false,
            top: 0,
            left: 0,
            selectedTag: {},
            affixTags: [],
        }
    },
    components: { ScrollPane, draggable },
    computed: {
        visitedViews() {
            return this.$store.state.tagsView.visitedViews
        },

        routes() {
            return this.$router.options.routes
        },

        isSticky() {
            return false
        },
    },

    methods: {
        isActive(route) {
            return this.$route.path.includes(route.path)
        },

        isAffix(tag) {
            return tag.meta && tag.meta.affix
        },

        filterAffixTags(routes) {
            let tags = []
            routes.forEach((route) => {
                if (route.meta && route.meta.affix) {
                    tags.push({
                        fullPath: route.path,
                        path: route.path,
                        name: route.name,
                        meta: { ...route.meta },
                    })
                }
                if (route.children) {
                    const tempTags = this.filterAffixTags(route.children)
                    if (tempTags.length >= 1) {
                        tags = [...tags, ...tempTags]
                    }
                }
            })
            return tags
        },

        initTags() {
            const affixTags = (this.affixTags = this.filterAffixTags(this.routes))
            for (const tag of affixTags) {
                // Must have tag name
                if (tag.name) {
                    this.$store.dispatch('tagsView/addVisitedView', tag)
                }
            }
        },

        addTags() {
            const { name } = this.$route
            if (name) {
                this.$store.dispatch('tagsView/addView', this.$route)
            }
            return false
        },

        moveToCurrentTag() {
            const tags = this.$refs.tag
            this.$nextTick(() => {
                for (const tag of tags) {
                    if (tag.to.path === this.$route.path) {
                        this.$refs.scrollPane.moveToTarget(tag)
                        // when query is different then update
                        if (tag.to.fullPath !== this.$route.fullPath) {
                            this.$store.dispatch('tagsView/updateVisitedView', this.$route)
                        }
                        break
                    }
                }
            })
        },

        refreshSelectedTag(view) {
            this.$store.dispatch('tagsView/delCachedView', view).then(() => {
                const { path } = view
                this.$nextTick(() => {
                    this.$router.replace({
                        path: path,
                    })
                })
            })
        },

        closeSelectedTag(view) {
            this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
                if (this.isActive(view)) {
                    this.toLastView(visitedViews, view)
                }
            })
        },

        closeOthersTags() {
            this.$router.push(this.selectedTag)
            this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
                this.moveToCurrentTag()
            })
        },

        closeAllTags(view) {
            this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
                if (this.affixTags.some((tag) => tag.path === view.path)) {
                    return
                }
                this.toLastView(visitedViews, view)
            })
        },

        toLastView(visitedViews, view) {
            const latestView = visitedViews.slice(-1)[0]
            if (latestView) {
                this.$router.push(latestView.path)
            } else {
                if (view.name === 'Dashboard') {
                    this.$router.replace({ path: '/redirect' + view.fullPath })
                } else {
                    this.$router.push('/')
                }
            }
        },

        closeMenu() {
            this.visible = false
        },

        handleScroll() {
            this.closeMenu()
        },
    },
}
</script>

<style lang="scss" scoped>
.sticky {
    position: fixed;
    top: 20px;
}

.tags-view-container {
    position: relative;
    padding: 4px;
    margin-bottom: 10px;
    width: 100%;
    z-index: 1000;
    background: #404044;
    -webkit-box-shadow: 0 3px 3px #3c3c40;
    -moz-box-shadow: 0 3px 3px #3c3c40;
    box-shadow: 0 3px 3px #3c3c40;

    .btn-group {
        margin-left: 4px;
    }
}

.flip-list-move {
    transition: transform 0.5s;
}
</style>
