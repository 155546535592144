import session from '../../utils/session.js'

export default {
    namespaced: true,

    state: {
        claimConfig: {
            states: [],
            prioritys: [],
            queues: [],
        },
    },

    actions: {
        onGetClaimConfig({ commit, state }) {
            if (state.claimConfig) {
                session.get('/claims/claim_config/').then((response) => {
                    commit('SET_CONFIG', response.data)
                })
            }
            return state
        },
    },

    getters: {
        getConfig(state) {
            return state.claimConfig
        },
    },

    mutations: {
        SET_CONFIG(state, value) {
            state.claimConfig = value
        },
    },
}
