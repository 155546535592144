const state = {
    employeeFilter: [],
}

const actions = {
    employeeFilter({ commit }, filter) {
        commit('SET_FILTER', filter)
        return filter
    },
}

const mutations = {
    SET_FILTER: (state, filter) => {
        state.employeeFilter = filter
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
