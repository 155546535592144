<template>
    <div ref="scrollContainer" :vertical="false" @click.prevent="handleScroll">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ScrollPane',
    data() {
        return {
            left: 0,
        }
    },

    computed: {
        scrollWrapper() {
            return this.$refs.scrollContainer
        },
    },

    mounted() {
        this.scrollWrapper.addEventListener('scroll', this.emitScroll, true)
    },

    beforeDestroy() {
        this.scrollWrapper.removeEventListener('scroll', this.emitScroll)
    },

    methods: {
        handleScroll(e) {
            const eventDelta = e.wheelDelta || -e.deltaY * 40
            const $scrollWrapper = this.scrollWrapper
            $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
        },

        emitScroll() {
            this.$emit('scroll')
        },
    },
}
</script>

<style lang="scss" scoped>
.scroll-container {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    width: 100%;

    ::v-deep {
        .el-scrollbar__bar {
            bottom: 0px;
        }

        .el-scrollbar__wrap {
            height: 49px;
        }
    }
}
</style>
