import store from '../store'

const acl = (rule) => {
    return store.getters.permission.includes(rule.replace(/'/g, '').replace(/"/g, ''))
}

export default {
    install: (Vue) => {
        Vue.$acl = acl
        Vue.prototype.$acl = acl
    },
}
