import axios from 'axios'
import Vue from 'vue'
import '@/assets/css/nprogress.css'
import store from '@/store'
import qs from 'qs'

const session = axios.create({
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',

    headers: {
        Authorization: `Token ${window.localStorage.getItem('TOKEN_STORAGE_KEY')}`,
    },

    // baseURL: 'http://172.16.3.35:8000',

    paramsSerializer: function (params) {
        return qs.stringify(params, {
            arrayFormat: 'comma',
        })
    },
    validateStatus: (code) => {
        switch (code) {
            case 401:
                if (!location.hash.match('login')) {
                    store.commit('user/REMOVE_TOKEN')
                }
                break
            case 400:
                return null
            case 405:
                return null
            case 403:
                return null
            case 500:
                return null
            default:
                return true
        }
    },
})

session.interceptors.response.use(
    (response) => {
        return response
    },
    ({ response }) => {
        const { detail } = response.data
        if (detail) Vue.$toast.error(detail)
        return Promise.reject(response)
    }
)
export default session
