import Layout from '@/layout'

const WorkRouter = {
    path: '/work/',
    name: 'Work',
    component: Layout,
    redirect: '/work/list',
    permission: 'work.view_work',
    meta: { title: 'Робота', icon: 'fa-wrench', cache: true },
    children: [
        {
            name: 'WorkList',
            component: () => import('@/modules/Work/WorkList'),
            path: 'list',
            hidden: true,
            permission: 'work.view_work',
            meta: {
                title: 'Список робіт',
                icon: 'fa-list',
                cache: true,
            },
        },
        {
            path: ':id/view',
            props: true,
            component: () => import('@/modules/Work/WorkView'),
            name: 'WorkView',
            meta: { title: 'Робота' },
            hidden: true,
        },
        {
            path: 'statistics',
            component: () => import('@/modules/Work/views/WorkStatistics'),
            name: 'WorkStatistics',
            permission: 'work.delete_work',
            meta: { title: 'Статистика' },
        },
    ],
}
export default WorkRouter
