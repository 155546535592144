<template>
    <div class="content-wrapper">
        <section class="content">
            <TagsView v-if="needTagsView" :class="{ fixed: toUp }" />
            <router-view />
        </section>
        <!--        <template v-if="getUser.extension">-->
        <!--            <eventCall />-->
        <!--            <CallButton />-->
        <!--        </template>-->
        <button
            style="position: fixed; bottom: 10px; right: 10px; z-index: 1500"
            class="btn btn-primary"
            @click="onScrollToTop"
            v-if="toUp"
        >
            <i class="fa fa-arrow-up" />
        </button>
    </div>
</template>

<script>
import TagsView from '../layout/components/TagsView'
// import eventCall from '../modules/Voip/components/eventCall'
// import CallButton from '../modules/Voip/components/CallButton'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'ContentWrap',
    data() {
        return {
            scrollY: 0,
        }
    },
    watch: {
        $route() {
            this.addTags()
        },
    },

    components: {
        TagsView,
        // eventCall,
        // CallButton,
    },

    mounted() {
        this.addTags()
        window.addEventListener('scroll', this.onScroll, { passive: true })
    },
    computed: {
        toUp() {
            return this.scrollY > 80
        },

        cachedViews() {
            return this.$store.state.tagsView.cachedViews
        },

        key() {
            return this.$route.path
        },

        ...mapState({
            needTagsView: (state) => state.tagsView.visitedViews.length,
        }),

        ...mapGetters(['getUser']),
    },

    methods: {
        async addTags() {
            const { name } = this.$route
            if (name) {
                this.$store.dispatch('tagsView/addView', this.$route)
            }
            return false
        },
        onScroll() {
            this.scrollY = window.scrollY
        },
        onScrollToTop() {
            window.scrollTo({ top: 0 })
        },
    },
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all 0.1s;
}

.fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
}

.fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.fixed {
    position: fixed;
    top: 32px;
    animation: step_top 0.5s;
}

@keyframes step_top {
    0% {
        top: 0;
    }
    100% {
        top: 32px;
    }
}
</style>
