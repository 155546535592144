<template>
    <div class="l-page">
        <div class="l-box">
            <div class="l-logo">
                <a href="/"><b>Admin</b>Tools</a>
            </div>
            <div class="l-box-body">
                <p class="l-box-msg">Sign in to start your session</p>
                <form v-on:submit.prevent="handleLogin">
                    <div class="form-group has-feedback">
                        <input
                            name="username"
                            type="text"
                            v-model="loginForm.username"
                            class="form-control"
                            placeholder="Login"
                        />
                        <span class="glyphicon glyphicon-user form-control-feedback"></span>
                    </div>
                    <div class="form-group has-feedback">
                        <input
                            name="password"
                            type="password"
                            v-model="loginForm.password"
                            class="form-control"
                            placeholder="Password"
                        />
                        <span class="glyphicon glyphicon-lock form-control-feedback" />
                    </div>
                    <button type="submit" class="btn btn-block btn-primary btn-sm">Sign In</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store'

export default {
    name: 'LoginView',
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
            },
            passwordType: 'password',
            capsTooltip: false,
            loading: false,
            showDialog: false,
            redirect: undefined,
            otherQuery: {},
        }
    },
    methods: {
        onMessage(text, type) {
            this.$toast.open({
                message: text,
                position: 'top',
                type: type,
            })
        },

        showPwd() {
            if (this.passwordType === 'password') {
                this.passwordType = ''
            } else {
                this.passwordType = 'password'
            }
            this.$nextTick(() => {
                this.password.focus()
            })
        },
        handleLogin() {
            store
                .dispatch('user/login', this.loginForm)
                .then(({ status }) => {
                    if (status === 200) {
                        this.$store.dispatch('user/accountDetails').then(() => {
                            this.onMessage('Login Success', 'success')
                        })
                    }
                })
                .catch(() => {
                    this.onMessage('Wrong Username or Password!', 'error')
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.l-page {
    background: #d2d6de;
    position: absolute;
    width: 100%;
    height: 100%;
}

.l-box,
.register-box {
    width: 360px;
    margin: 7% auto;
    background: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 3px #3c3c40;
    -moz-box-shadow: 0 3px 3px #3c3c40;
    box-shadow: 0 3px 3px #3c3c40;
}

.l-box-body,
.register-box-body {
    padding: 20px;
    border-top: 0;
    color: #666;
}

.l-logo {
    font-size: 35px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 300;
    background: #404044;
    background: -moz-linear-gradient(#535357, #3c3c3f);
    background: -ms-linear-gradient(#535357, #3c3c3f);
    background: -webkit-linear-gradient(#535357, #3c3c3f);
    background: linear-gradient(#535357, #3c3c3f);
    -webkit-box-shadow: 0 3px 3px #3c3c40;
    -moz-box-shadow: 0 3px 3px #3c3c40;
    box-shadow: 0 3px 3px #3c3c40;
}

.l-logo a {
    color: #fff;
}

$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.l-container {
    min-height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: hidden;

    .l-form {
        position: relative;
        width: 520px;
        max-width: 100%;
        padding: 160px 35px 0;
        margin: 0 auto;
        overflow: hidden;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;

        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .svg-container {
        padding: 6px 5px 6px 15px;
        color: $dark_gray;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;

        .title {
            font-size: 26px;
            color: $light_gray;
            margin: 0 auto 40px auto;
            text-align: center;
            font-weight: bold;
        }
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
        user-select: none;
    }

    .thirdparty-button {
        position: absolute;
        right: 0;
        bottom: 6px;
    }

    @media only screen and (max-width: 470px) {
        .thirdparty-button {
            display: none;
        }
    }
}
</style>
