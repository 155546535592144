<template>
    <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
            <router-view :key="key" />
        </keep-alive>
    </transition>
</template>

<script>
export default {
    name: 'AppLayout',
    computed: {
        cachedViews() {
            return this.$store.state.tagsView.cachedViews
        },
        key() {
            return this.$route.path
        },
    },
}
</script>

<style scoped></style>
