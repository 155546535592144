import session from '../utils/session'

export const login = (username, password) => {
    return session.post('/api-token-auth/', { username, password })
}

export const logout = () => {
    return session.post('/logout/', {})
}

export const isAuthenticated = () => {
    return session.get('/check_auth/')
}

export const getAccountDetails = () => {
    return session.get('/user_details/')
}
