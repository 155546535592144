<template>
    <div id="app">
        <login-view v-if="!isAuthenticated" />
        <main-app v-else />
        <div v-if="!isAlive" class="is-alive">
            <span>Disconnected from Server</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginView from '@/views/login'
import MainApp from '@/layout/MainApp'

export default {
    name: 'App',
    components: { MainApp, LoginView },
    data() {
        return {
            socket: this.$ws('/session/'),
            isAlive: true,
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getUser']),
    },

    mounted() {
        this.socket.onopen = () => {
            this.isAlive = true
        }

        this.socket.onerror = () => {
            this.isAlive = false
        }
        //     this.$getLocation()
        //     .then(coordinates => {
        //         console.log(coordinates);
        //     });
    },
}
</script>

<style scoped>
.is-alive {
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 3200;
    background-color: rgba(15, 15, 16, 0.5);
    text-align: center;
}

.is-alive span {
    font-size: 54px;
    color: #ffffff;
}
</style>
