const state = {
    EventCalls: [],
}

const actions = {
    onAddOrChangeCall({ commit }, eventCall) {
        commit('SET_EVENT', eventCall)
    },
}

const mutations = {
    SET_EVENT: (state, eventCall) => {
        const exist = state.EventCalls.map((item) => {
            return item.callerIdNum
        }).indexOf(eventCall.callerIdNum)
        if (exist === -1) {
            state.EventCalls.push(eventCall)
        } else {
            state.EventCalls[exist].active_call = eventCall.active_call
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
