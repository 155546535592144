import Layout from '@/layout'

const OltRouter = {
    name: 'Olt',
    path: '/olt/',
    redirect: '/olt/view',
    component: Layout,
    permission: 'olt.view_olt',
    meta: { title: 'Olt', icon: 'fa-list', cache: true },
    children: [
        {
            path: 'view',
            component: () => import('@/modules/Olt/index'),
            name: 'OltDash',
            meta: { title: 'Olt', icon: 'list', cache: true },
        },
        {
            path: 'onu',
            component: () => import('@/modules/Olt/components/OnuList'),
            hidden: true,
            meta: { title: 'Onu List', icon: 'list' },
        },
        {
            path: ':old_id/onu',
            component: () => import('@/modules/Olt/components/OnuList'),
            name: 'OnuList',
            props: { exclude: ['olt'] },
            hidden: true,
            meta: { title: 'Onu List', icon: 'list' },
        },
        {
            path: 'map',
            component: () => import('@/modules/Olt/components/Map'),
            name: 'Map',
            meta: { title: 'Map', icon: 'map' },
        },
    ],
}
export default OltRouter
