import Layout from '@/layout'

const claimsRouter = {
    path: '/claims/',
    name: 'Claims',
    component: Layout,
    redirect: '/claims/list',
    permission: true,
    meta: { title: 'Заявки', icon: 'fa-list', cache: true },
    children: [
        {
            name: 'ClaimsList',
            component: () => import('@/modules/Claims/ClaimsList'),
            path: 'list',

            permission: 'claims.view_claims',
            hidden: true,
            props: true,
            meta: { title: 'Список заявок', icon: 'fa-list', cache: true },
        },
        {
            name: 'ClaimView',
            path: ':id/view/',
            component: () => import('@/modules/Claims/components/ClaimView'),

            meta: { title: 'Заявка' },
            hidden: true,
        },
    ],
}

export default claimsRouter
