const getters = {
    isAuthenticated: (state) => state.user.authenticating,
    getUser: (state) => state.user.user,
    permission: (state) => state.user.permission,
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
    events: (state) => state.events.events,
    EventCalls: (state) =>
        state.voip.EventCalls.sort((a, b) => {
            return b.unique_id - a.unique_id
        }),
    employeeFilter: (state) => state.employee.employeeFilter,
    errorLogs: (state) => state.errorLog.logs,
}
export default getters
