<template>
    <header class="main-header">
        <a class="logo">
            <span class="logo-mini"><b>A</b>TL</span>
            <span class="logo-lg"><b>Admin</b>Tools </span>
        </a>
        <nav class="navbar navbar-static-top">
            <a class="sidebar-toggle" @click="onActiveMenu">
                <i class="fa-regular fa-bars" />
            </a>
            <div class="navbar-custom-menu">
                <ul class="nav navbar-nav">
                    <ErrorLog />
                    <li class="dropdown notifications-menu">
                        <a href="#" class="dropdown_toggle" data-toggle="dropdown">
                            <i class="fa-regular fa-bell"></i>
                            <span class="label label-warning">{{ events.length }}</span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="header">You have {{ events.length }} messages</li>
                            <li>
                                <ul class="menu">
                                    <li v-for="event in events" :key="event.id">
                                        <a href="#">
                                            <i class="fa fa-users text-aqua"></i> {{ event.text }}
                                            <p>{{ event.desc }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="footer"><a href="#">View all</a></li>
                        </ul>
                    </li>
                    <li class="dropdown messages-menu">
                        <a href="#" class="dropdown_toggle" data-toggle="dropdown">
                            <i class="fa-regular fa-envelope" />
                            <span class="label label-success">{{ CartItem.length }}</span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="header">У вас {{ CartItem.length }} позиції</li>
                            <li>
                                <ul class="menu">
                                    <li v-for="cart in CartItem" :key="cart.id">
                                        <a href="#">
                                            <div class="pull-left">
                                                <img
                                                    :src="cart.material_obj.img"
                                                    class="img-circle"
                                                    alt="User Image"
                                                />
                                            </div>
                                            <h4>
                                                {{ cart.material_obj.name }}
                                                <small
                                                    ><i class="fa"></i> {{ cart.qty
                                                    }}{{ cart.material_obj.unit_slug }}</small
                                                >
                                            </h4>
                                            <p>{{ cart.material_obj.categorie_slug }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="footer"><a href="#"></a></li>
                        </ul>
                    </li>
                    <!--<VoipList/>-->

                    <li class="dropdown user user-menu">
                        <a href="#" class="dropdown_toggle" data-toggle="dropdown">
                            <img :src="getPhoto" class="user-image" alt="" />
                            <span class="hidden-xs">{{ getUser.username }}</span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="user-header">
                                <img :src="getPhoto" class="img-circle" alt="" />
                                <p>{{ getUser.first_name }} - {{ getUser.last_name }}</p>
                            </li>
                            <li class="user-body"></li>
                            <li class="user-footer">
                                <div class="pull-right">
                                    <button class="btn btn-default btn-flat" @click="logout">
                                        Logout
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorLog from '@/components/ErrorLog'

export default {
    name: 'NaviBar',

    data() {
        return {
            socket: this.$ws('/events/'),
        }
    },

    methods: {
        onActiveMenu() {
            const el = document.body
            el.classList.toggle(this.classMenu())
        },

        classMenu() {
            return window.window.screen.width < 765 ? 'sidebar-open' : 'sidebar-collapse'
        },

        async logout() {
            await this.$store.dispatch('user/logout')
        },
    },
    computed: {
        ...mapGetters({
            CartItem: 'Cart/ItemsCart',
            getUser: 'getUser',
            events: 'events',
        }),

        getPhoto() {
            if (this.getUser.employee) {
                return this.getUser.employee.photo
            }
            return '/media/employee/photo/avatar.jpg'
        },
    },
    mounted() {
        this.socket.onopen = () => {}
        this.socket.onerror = () => {}
        this.socket.onmessage = (message) => {
            const data = JSON.parse(message.data)
            if (data.open) {
                this.$store.dispatch('events/addEvents', data.event)
            } else if (data.close) {
                this.$store.dispatch('events/remEvents', data.event)
            }
        }
        this.$store.dispatch('Cart/getItemsCart')
    },

    components: {
        ErrorLog,
    },

    destroyed() {
        this.socket.close()
    },
}
</script>

<style lang="scss" scoped>
.skin-red .main-header {
    -webkit-box-shadow: 0 3px 3px #3c3c40;
    -moz-box-shadow: 0 3px 3px #3c3c40;
    box-shadow: 0 3px 3px #3c3c40;

    .navbar {
        background: #404044;
        -webkit-box-shadow: 0 3px 3px #3c3c40;
        -moz-box-shadow: 0 3px 3px #3c3c40;
        box-shadow: 0 3px 3px #3c3c40;

        .sidebar-toggle {
            transition: 1s;
        }
    }

    .logo {
        background-color: #404044;
        height: auto;
    }

    & .nav > li > a > .label {
        position: absolute;
        top: 2px;
        right: 2px;
        text-align: center;
        font-size: 9px;
        padding: 2px 3px;
        line-height: 0.9;
    }
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 5px 10px;
    border-radius: 5px;
}

.navbar-nav > li > a {
    padding-top: 5px;

    text-decoration: none;
    text-shadow: 0 1px 0 #000;

    border-right: 1px solid #323235;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    background: -moz-linear-gradient(#535357, #3c3c3f);
    background: -ms-linear-gradient(#535357, #3c3c3f);
    background: -webkit-linear-gradient(#535357, #3c3c3f);
    background: linear-gradient(#535357, #3c3c3f);

    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 0 #292929;
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 0 #292929;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 0 #292929;
}

.logo-mini {
    background-color: #00c021;
    box-shadow: 0 3px 3px #00a51c;

    & > b {
        color: #ff0400;
        transition: color 1s;
    }
}

.logo-lg {
    & > b {
        color: #000000;
        transition: 1s;
    }
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.date-time {
    text-align: center;
    font-size: x-large;
    color: #f00;
}
</style>
