<template>
    <li v-if="errorLogs.length > 0">
        <a @click="showError = !showError">
            <i class="fa fa-bell-o"></i>
            <span class="label label-danger">{{ errorLogs.length }}</span>
        </a>
        <static-dialog size="lg" :visible.sync="showError">
            <template #body>
                <button type="button" class="btn btn-primary" @click="clearAll">Clear Log</button>
                <div
                    v-for="(e, index) in errorLogs"
                    :key="index"
                    class="alert alert-danger"
                    role="alert"
                >
                    <p>{{ e.url }}</p>
                    <p>{{ e.err.message }}</p>
                    <p>{{ e.err.stack }}</p>
                </div>
            </template>
        </static-dialog>
    </li>
</template>

<script>
export default {
    name: 'ErrorLog',
    data() {
        return {
            showError: false,
        }
    },
    methods: {
        clearAll() {
            this.dialogTableVisible = false
            this.$store.dispatch('errorLog/clearErrorLog')
        },
    },
    computed: {
        errorLogs() {
            return this.$store.getters.errorLogs
        },
    },
    components: {
        StaticDialog: () => import('@/components/StaticDialog'),
    },
}
</script>

<style scoped>
.navbar-nav > li > a {
    padding-top: 5px;
    text-decoration: none;
    text-shadow: 0 1px 0 #000;
    border-right: 1px solid #323235;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background: -webkit-linear-gradient(#535357, #3c3c3f);
    background: -webkit-gradient(linear, left top, left bottom, from(#535357), to(#3c3c3f));
    background: -o-linear-gradient(#535357, #3c3c3f);
    background: linear-gradient(#535357, #3c3c3f);
    -webkit-box-shadow: inset 0 1px 0px rgba(255, 255, 255, 0.2), 0 1px 0px #292929;
    box-shadow: inset 0 1px 0px rgba(255, 255, 255, 0.2), 0 1px 0px #292929;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
