import { login, logout as api_logout, getAccountDetails } from '../../api/auth'
import session from '../../utils/session'
import { parseRouter } from '@/utils/parseRoute'
import store from '../../store'
import { resetRouter } from '@/router'

const state = {
    token: '',
    user: '',
    authenticating: false,
    permission: [],
}

const actions = {
    login({ commit }, { username, password }) {
        resetRouter()
        return login(username, password).then(({ data, status }) => {
            commit('SET_TOKEN', data.token)
            return { status: status }
        })
    },

    logout({ commit }) {
        return api_logout()
            .then(() => {
                resetRouter()
                commit('LOGOUT')
            })
            .finally(() => commit('REMOVE_TOKEN'))
    },

    accountDetails({ commit }) {
        return new Promise((resolve, reject) => {
            getAccountDetails()
                .then(({ data }) => {
                    const token = localStorage.getItem('TOKEN_STORAGE_KEY')
                    commit('SET_TOKEN', token)
                    commit('SET_USER', data)
                    commit('SET_PERMISSION', data.permission)
                    const routeList = parseRouter()
                    store.dispatch('permission/addRoute', routeList)
                    resolve(data)
                })
                .catch((error) => {
                    commit('REMOVE_TOKEN')
                    reject(error)
                })
        })
    },
}

const mutations = {
    LOGOUT: (state) => {
        state.authenticating = false
        state.error = false
    },

    SET_TOKEN: (state, token) => {
        state.token = token
        document.cookie = `Token=${token}; path=/; SameSite=Lax;`
        localStorage.setItem('TOKEN_STORAGE_KEY', token)
        session.defaults.headers.Authorization = `Token ${token}`
    },

    REMOVE_TOKEN: (state) => {
        state.token = null
        state.authenticating = false
        document.cookie = 'Token=""'
        localStorage.removeItem('TOKEN_STORAGE_KEY')
        delete session.defaults.headers.Authorization
    },

    SET_USER: (state, user) => {
        state.user = user
        state.authenticating = true
        if (user.employee) {
            state.user.first_name = user.employee.first_name
            state.user.last_name = user.employee.last_name
            state.user.photo = user.employee.photo
        }
    },

    SET_PERMISSION: (state, permission) => {
        state.permission = permission
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
