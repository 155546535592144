import Layout from '@/layout'
import Vue from 'vue'

const WarehouseRouter = {
    path: '/warehouse/',
    name: 'WarehouseApp',
    component: Layout,
    redirect: '/warehouse/list',
    permission: true,
    cache: true,
    meta: { title: 'Склад', icon: 'fa-list' },
    children: [
        {
            path: 'tmc',
            component: () => import('../views/TmcView'),
            name: 'TmcView',
            meta: { title: 'ТМЦ', icon: 'fa-list', cache: true },
        },
        {
            path: 'list',
            component: () => import('../../Warehouse'),
            permission: true,
            name: 'WarehouseList',
            meta: { title: 'Склади', icon: 'fa-list', cache: true },
        },
        {
            path: ':id/view',
            component: () => import('../views/WarehouseView'),
            name: 'WarehouseView',
            permission: true,
            hidden: true,
            meta: { title: 'Склад', icon: 'fa-list', cache: true },
        },
        {
            path: 'Categories',
            component: () => import('../views/Categories'),
            name: 'CategoriesView',
            meta: { title: 'Категорії', icon: 'fa-list', cache: true },
        },
        {
            path: ':id/materials',
            component: () => import('../components/WarehouseMaterials'),
            hidden: true,
            name: 'WarehouseMaterials',
            meta: { title: 'Material List', icon: 'fa-list', cache: true },
        },
        {
            path: 'items',
            component: () => import('../views/ItemsView'),
            name: 'ItemsView',
            meta: { title: 'Item List', icon: 'fa-list', cache: true },
        },
        {
            path: 'item/:id/view',
            component: () => import('../components/ItemView'),
            hidden: true,
            name: 'ItemView',
            meta: { title: 'Item View', icon: 'fa-list', cache: true },
        },
        {
            path: 'material/:id/view',
            component: () => import('../components/MaterialItemView'),
            hidden: true,
            name: 'MaterialItemView',
            meta: { title: 'Material View', icon: 'fa-list', cache: true },
        },
        {
            path: 'invoice/list',
            component: () => import('../views/InvoiceListView'),
            name: 'InvoiceListView',
            meta: { title: 'Накладні', icon: 'fa-list', cache: true },
        },
    ],
}

Vue.$acl('warehouse.delete_warehouse')
    ? WarehouseRouter.children.push(
          {
              path: 'cartlist',
              component: () => import('../views/CartListView'),
              name: 'CartListView',
              meta: { title: 'Cart List', icon: 'fa-list', cache: true },
          },
          {
              path: 'cart/:id/view',
              component: () => import('../views/CartView'),
              hidden: true,
              name: 'CartView',
              meta: { title: 'Cart View', icon: 'fa-list', cache: true },
          }
      )
    : WarehouseRouter.children.push({
          path: 'cart/view',
          component: () => import('../views/CartView'),
          name: 'CartView',
          meta: { title: 'Cart View', icon: 'fa-list', cache: true },
      })

export default WarehouseRouter
